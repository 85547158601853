import * as React from "react";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import favicon16 from "../assets/favicons/favicon-16x16.png";
import favicon32 from "../assets/favicons/favicon-32x32.png";

const Seo = ({ title, description, pathname }) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    image,
    siteUrl,
    twitterUsername,
    keywords,
  } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ``}`,
    twitterUsername,
    keywords,
  };
  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords}></meta>
      <meta name="image" content={seo.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:creator" content={seo.twitterUsername} />
      <link rel="icon" type="image/png" sizes="16x16" href={favicon16}></link>
      <link rel="icon" type="image/png" sizes="32x32" href={favicon32}></link>
    </>
  );
};

export default Seo;
